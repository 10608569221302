import React, {useState} from "react";
//import { works } from "../../data/projects";
import { useStaticQuery, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";
import {fetchIPJSON}from '../../help';

export default function Projects() {
  const [city, setCity] = useState(null);
  const data = useStaticQuery(graphql`
    {
      allProjectsJson {
        nodes {
          name
          color
          label
          hiddenCity
          link
          second_img
          services
          type
          thumbnail {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
          secondImg {
            childImageSharp {
              gatsbyImageData(width: 1000)
            }
          }
        }
      }
    }
  `);
  //console.log(data);
  const works = data.allProjectsJson.nodes;
  console.log(works[0].second_img);
  if (typeof window !== 'undefined'){
    const ip = window.localStorage.getItem('ip') || null;
    if(ip){
      fetchIPJSON(ip).then(res => {
        setCity(res.city);
      });
    }
  }
  return (
    <div className="works-section relative z-10  mx-5 sm:mx-auto">
      <div className="text-center lg:w-3/5 mx-auto mt-10">
        <h1 className="font-black text-3xl sm:text-4xl md:text-5xl m-4 dark:text-white">
          Some of my work
        </h1>
        <p className="text-lg sm:text-xl md:text-1xl text-gray-500 dark:text-gray-300 mb-10 lg:px-2">
          It’s time to see some work. Here are a few projects and pitch work
          I’ve done for clients.I care about code quality and building truly
          pleasant user experiences, with attention to small but important
          details.
        </p>
      </div>
      <div className="mt-5 xl:w-4/5 mx-auto mb-5 lg:px-10">
        {works.map((work, x) => (
          !work.hiddenCity.includes(city) && (<div
            key={x}
            className="project_work flex-col sm:flex-row flex w-100 rounded p-5 sm:p-10 h-480 my-10 overflow-hidden shadow-lg"
            style={{ background: work.color }}
          >
            <div className="flex-col sm:w-2/5 text-white h-100 flex items-start justify-center">
              <span className="bg-gray-500 bg-opacity-20 px-2 py-1 font-medium mb-4 text-xs rounded inline-block upppercase">
                {work.label}
              </span>
              <h3 className="py-5 text-2xl sm:text-4xl font-bold pr-2 sm:leading-10">
                {work.name}
              </h3>
              <div className="services">
                <ul>
                  {work.services &&
                    work.services.map((service, x) => (
                      <li
                        key={x}
                        className="flex justify-start items-center opacity-80"
                      >
                        <svg
                          className="mr-1"
                          fill="none"
                          height="16"
                          stroke="#fff"
                          strokeWidth="1"
                          viewBox="0 0 24 24"
                          width="16"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path d="M5 12h13M12 5l7 7-7 7"></path>
                        </svg>
                        {service}
                      </li>
                    ))}
                </ul>
              </div>
              {work.link && (
                <a
                  href={`${work.link}`}
                  target="_blank"
                  className="flex items-center p-2 rounded hover:bg-gray-200 hover:bg-opacity-20 mt-4"
                  rel="noreferrer noopener"
                >
                  <svg
                    width="20"
                    height="20"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                  >
                    <path
                      d="M11.706 13.707l6.293-6.293V11h2V4h-7v2h3.585l-6.293 6.293 1.415 1.414z"
                      fill="currentColor"
                    ></path>
                    <path
                      d="M19.002 18v-4h-2v4h-11V7h4V5h-4a2 2 0 00-2 2v11a2 2 0 002 2h11a2 2 0 002-2z"
                      fill="currentColor"
                    ></path>
                  </svg>
                  <span className="ml-2 text-xs sm:text-sm font-medium relative link-web">
                   { work?.type == "app" ? "View app" : "View Website" }
                  </span>
                </a>
              )}
            </div>
            <div className="work sm:flex-col sm:w-3/5 relative">
              <div className="img-left top-10 sm:top-4 md:left-2	relative w-100 sm:w-9/12 z-20 rounded">
                <GatsbyImage
                  image={getImage(work.thumbnail)}
                  className="w-100"
                  alt={work.name}
                />
              </div>
              {!work?.secondImg && work.second_img ? (
                <div className="img-right bottom-2 right-4 absolute w-6/12 z-10 rounded hidden sm:block">
                  <GatsbyImage
                    image={getImage(work.thumbnail)}
                    className=""
                    alt={work.name}
                  />
                </div>
              ) : (
                <div className="img-right bottom-2 right-4 absolute w-6/12 z-10 rounded hidden sm:block">
                  <GatsbyImage
                    image={getImage(work.secondImg)}
                    className=""
                    alt={work.name}
                  />
                </div>
              )}
            </div>
          </div>)
        ))}
      </div>
    </div>
  );
}
