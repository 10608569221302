import * as React from "react";
import SEO from "../components/Layout/SEO";
import Projects from "../components/Sections/Projects";

const WorkPage = () => {
  return (
    <>
      <SEO
        title="Portfolio"
        description="Here is a small run down of some of my recent work including some quite thorough case studies of web design and web development projects."
      />
      <Projects />
    </>
  );
};

export default WorkPage;
